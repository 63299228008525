import React, { useState, useEffect } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import CookieDisclaimer from './CookieDisclaimer/CookieDisclaimer';

const Layout = ({ children }) => {
  return (
    <>
      <ScrollRestoration />
      <Header />
      {children}
      <Footer />
      <CookieDisclaimer />
    </>
  );
};

export default Layout;
